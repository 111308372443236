import config from '@/config'
import Paper from 'paper'
import Vue from 'vue'
import _ from 'lodash'
import { mapState, mapGetters } from 'vuex'

import { productTypes } from '@/helper/api/types'

import MixinMath from '@/mixins/math'
import MixinDom from '@/mixins/dom'
import MixinCosts from '@/mixins/costs'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

const FONT_SIZE = 12
const MAX_VIEW_HEIGHT = 377.95275591 // 100mm

const OVERVIEW_AREAS_SVG_WIDTH = 642.51968504 // 170mm
const OVERVIEW_AREAS_SVG_HEIGHT = 377.95275591 // 100mm

const SINGLE_AREA_SVG_WIDTH = 642.51968504
const SINGLE_AREA_SVG_HEIGHT = 377.95275591

const A4_WIDTH = 793.7007874
const A4_HEIGHT = 1122.519685

const A1_AREAS_WIDTH = 2.3 * A4_WIDTH
const A1_AREAS_HEIGHT = A4_HEIGHT

const AREA_PATH_COLOR_R = 230
const AREA_PATH_COLOR_G = 236
const AREA_PATH_COLOR_B = 213
const AREA_PATH_ALPHA = 1

const TEXT_BOXES = []

function getInitialComponentState () {
  return {
    unit: null,
    totalPrices: {
      turf: {
        value: 0,
        formatted: ''
      },
      consumables: {
        value: 0,
        formatted: ''
      },
      project: {
        value: 0,
        formatted: ''
      }
    },
    totalConsumables: {
      infills: [],
      seamtapes: [],
      adhesives: [],
      subbases: {
        pads: [],
        pedestals: [],
        others: []
      }
    }
  }
}

/**
 * Collect all data needed to create pdf
 * Use: export.js
 * @displayName Export - PDF
 */
export default {
  name: 'ExportPdfMixin',
  mixins: [
    MixinMath,
    MixinDom,
    MixinCosts,
    MixinProductInLanguage
  ],
  data: () => {
    return getInitialComponentState()
  },
  computed: {
    ...mapState({
      underlayImage: state => state.files.currentSurfaceUnderlay
    }),
    ...mapGetters({
      getSubbaseByIdType: 'products/getSubbaseByIdType',
      getInfillById: 'products/getInfillById',
      getProductById: 'products/getProductById',
      getSeamtapeById: 'products/getSeamtapeById',
      getAdhesiveById: 'products/getAdhesiveById'
    })
  },
  methods: {
    /**
         * Turf Overview PDF
         * @param {[]} objects
         * @param {{}} measure
         * @param {{}} products
         * @param {{}} language
         * @public
         */
    mx_pdf_get_turf_project_details (objects, measure, products, language) {
      Object.assign(this.$data, getInitialComponentState())
      this.units = measure.unit
      let areas = []
      const total = {}

      total.divisions = objects.length

      let totalArea = 0

      objects.forEach((object, index) => {
        const areaSize = Number(object.area)

        const product = products.find(p => p.original.id === object.product.id)

        areas[index] = {
          name: `${language.areaPrefix} ${object.getName()}`,
          size: {
            value: areaSize.toFixed(2),
            unit: this.$settings.areaUnit
          },
          scope: {
            value: this.$_mx_pdf_object_scope(object, measure),
            unit: this.$settings.lengthUnit
          },
          bounds: {
            width: 0,
            height: 0,
            unit: this.$settings.lengthUnit
          },
          type: product.original.category,
          productName: this.mx_product_in_language(product.original, ['name']),
          data: {
            id: object.id,
            obj: object,
            objType: object.type,
            points: object.getPoints(),
            childrenPoints: object.getChildrenPoints(),
            childrenPaths: object.getChildrenPaths(),
            viewAngle: object.product.angle,
            tracks: object.product.tracks,
            product: _.cloneDeep(object.product)
          }
        }

        totalArea += areaSize
      })

      total.totalArea = {
        value: totalArea.toFixed(2),
        unit: this.$settings.areaUnit
      }

      const result = this.$_mx_pdf_create_project_svg_and_datatable(areas, measure, language)

      areas = result.areas
      total.svgOverview = result.overview

      this.totalPrices.turf.formatted = this.$world.currencyConvert(this.totalPrices.turf.value, true)
      this.totalPrices.consumables.formatted = this.$world.currencyConvert(this.totalPrices.consumables.value, true)
      this.totalPrices.project.formatted = this.$world.currencyConvert(this.totalPrices.project.value, true)

      total.prices = this.totalPrices
      total.consumables = this.$_mx_pdf_combine_consumable_calculation_overview(_.cloneDeep(this.totalConsumables))

      areas = areas.sort((a, b) => {
        const x = a.name
        const y = b.name
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
      })

      return {
        areas: areas,
        raster: result.raster,
        total: total
      }
    },
    $_mx_pdf_object_scope (object, measure) {
      const lengths = this.mx_math_calculateLengthsFromDomLines(this.mx_dom_getLinesFromObject(object.id), measure)
      const combinedLengths = lengths.map(l => l.length).reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue))

      let cl = this.$_mx_math_getCl(combinedLengths, measure)

      if (object.type === config.objects.types.CIRCLE) {
        cl = 2 * Math.PI * cl
      }

      return this.mx_math_roundDecimal(cl)
    },
    $_mx_pdf_combine_consumable_calculation_overview (consumables) {
      const _consumables = {
        infills: this.$_mx_pdf_combine_consumable(consumables.infills),
        seamtapes: this.$_mx_pdf_combine_consumable(consumables.seamtapes),
        adhesives: this.$_mx_pdf_combine_consumable(consumables.adhesives),
        subbases: {
          pads: this.$_mx_pdf_combine_consumable(consumables.subbases.pads),
          pedestals: this.$_mx_pdf_combine_consumable(consumables.subbases.pedestals),
          others: this.$_mx_pdf_combine_consumable(consumables.subbases.others)
        }
      }

      return _consumables
    },
    $_mx_pdf_combine_consumable (elements) {
      const map = new Map()
      elements.forEach(element => {
        if (map.has(element.name)) {
          const el = map.get(element.name)
          for (const prop in element.costs) {
            el.costs[prop] += Number(element.costs[prop])
          }
          el.costs.formatted = this.$world.currencyConvert(el.costs.value, true)
          map.set(element.name, el)
        } else {
          map.set(element.name, _.cloneDeep(element))
        }
      })

      return Array.from(map.values())
    },
    /**
         * Turf Installation Plan A1
         * @param objects
         * @param measure
         * @param products
         * @param language
         * @returns {{areasTable: [], plan: {svg: SVGElement | string, viewbox: string}}}
         * @public
         */
    mx_pdf_get_turf_project_a1 (objects, measure, products, language) {
      Object.assign(this.$data, getInitialComponentState())
      const a1Group = new Paper.Group()
      const unitsGroup = new Paper.Group()
      const tracksGroup = new Paper.Group()
      const tracksDescriptionGroup = new Paper.Group()
      const areasDataTable = []

      objects.forEach((object, index) => {
        const areaSize = Number(object.area)
        const product = products.find(p => p.original.id === object.product.id)

        areasDataTable.push({
          name: `${language.areaPrefix} ${object.getName()}`,
          shortName: `${language.trackNamePrefix}${object.getName()}`,
          size: {
            value: areaSize.toFixed(2),
            unit: this.$settings.areaUnit
          },
          type: product.original.category,
          productName: this.mx_product_in_language(product.original, ['name']),
          angle: object.product.angle
        })

        const paperObject = this.$_mx_pdf_get_area_as_paper_object(object)
        const unitsResult = this.$_mx_pdf_get_area_units(object.id, object.getPoints(), object.getChildrenPoints(), object.type, measure, 12)

        const trackResult = this.$_mx_pdf_get_area_tracks(areasDataTable[index].name.replace(/^\D+/g, ''), object.product.tracks, measure, language, 12)

        // combine all element with the same type in one group
        tracksGroup.addChildren(trackResult.tracks.getItems({ class: 'Path' }))
        unitsGroup.addChildren(unitsResult.children)

        // add area to svg group
        a1Group.addChild(paperObject)
      })

      a1Group.addChild(tracksGroup)

      // update units font size
      const fontSize = (a1Group.bounds.height / A1_AREAS_HEIGHT) * FONT_SIZE
      unitsGroup.children.map(unit => {
        unit.fillColor = (unit.name === 'unitAngle') ? new Paper.Color(0.5) : unit.fillColor
        unit.fontSize = fontSize
        return unit
      })

      objects.forEach((object, index) => {
        const result = this.$_mx_pdf_get_area_tracks(`${language.trackNamePrefix}${object.getName()}`, object.product.tracks, measure, language, fontSize, true)
        tracksDescriptionGroup.addChildren(result.tracks.getItems({ class: 'PointText' }))
      })

      // update track description font size and position
      tracksDescriptionGroup.children.map(trackDescription => {
        trackDescription.fillColor = 'black'
        return trackDescription
      })

      a1Group.addChild(unitsGroup)
      a1Group.addChild(tracksDescriptionGroup)

      // add ruler
      const rulerGroup = new Paper.Group()
      const length = measure.fromPixels / measure.toUnit * 10

      const rulerPath = new Paper.Path(
        new Paper.Point(a1Group.bounds.x, a1Group.bounds.y + a1Group.bounds.height + 0.5)
      )
      rulerPath.add(
        new Paper.Point(a1Group.bounds.x, a1Group.bounds.y + a1Group.bounds.height + 1),
        new Paper.Point(a1Group.bounds.x + length, a1Group.bounds.y + a1Group.bounds.height + 1),
        new Paper.Point(a1Group.bounds.x + length, a1Group.bounds.y + a1Group.bounds.height + 0.5)
      )
      rulerPath.strokeWidth = 1
      rulerPath.strokeColor = 'black'

      rulerGroup.addChild(rulerPath)

      const rulerWidth = new Paper.PointText({
        point: [a1Group.bounds.x + (length / 2) - 0.2, a1Group.bounds.y + a1Group.bounds.height + 0.3],
        content: `10 ${this.$settings.lengthUnit}`,
        fillColor: 'black',
        fontSize: fontSize
      })

      rulerWidth.bounds.x = rulerWidth.bounds.x - (rulerWidth.bounds.width / 2)

      rulerGroup.addChild(rulerWidth)

      a1Group.addChild(rulerGroup)
      a1Group.translate(new Paper.Point(a1Group.bounds.x * -1, a1Group.bounds.y * -1))
      // fit plan
      const planSize = new Paper.Size(A1_AREAS_WIDTH, A1_AREAS_HEIGHT)
      const planFitBounds = new Paper.Rectangle(new Paper.Point(0, 0), planSize)

      a1Group.fitBounds(planFitBounds)

      const planSVG = a1Group.exportSVG({ asString: true })
      const viewbox = `${a1Group.bounds.x} ${a1Group.bounds.y} ${a1Group.bounds.width} ${a1Group.bounds.height}`

      tracksDescriptionGroup.removeChildren()
      tracksDescriptionGroup.remove()
      tracksGroup.removeChildren()
      tracksGroup.remove()
      unitsGroup.removeChildren()
      unitsGroup.remove()
      a1Group.removeChildren()
      a1Group.remove()

      return {
        plan: {
          svg: planSVG,
          viewbox: viewbox
        },
        areasTable: areasDataTable
      }
    },
    /**
         *
         * @param areas
         * @param measure
         * @param language
         * @returns {{overview: SVGElement | string, areas: *}}
         */
    $_mx_pdf_create_project_svg_and_datatable (areas, measure, language) {
      const overviewGroup = new Paper.Group()

      areas.forEach((area, key) => {
        overviewGroup.addChild(this.$_mx_pdf_get_area_as_paper_object(area.data.obj))

        const singleAreaResult = this.$_mx_pdf_create_project_area_svg(area, measure, language)

        area.svg = singleAreaResult.svg
        area.tracksDataTable = singleAreaResult.dataTable
        area.tracksSum = singleAreaResult.sum
        area.viewbox = singleAreaResult.viewbox

        area.breakdown = this.$_mx_pdf_get_area_materials_from_product(area.data, area.tracksSum, area.size)

        areas[key] = area
      })

      const fontSize = (overviewGroup.bounds.height / MAX_VIEW_HEIGHT) * FONT_SIZE

      areas.forEach((area, key) => {
        const object = this.$_mx_pdf_get_area_as_paper_object(area.data.obj)

        // get area width and height
        areas[key].bounds.width = this.mx_math_roundDecimal(this.$_mx_math_getCl(object.bounds.width, measure))
        areas[key].bounds.height = this.mx_math_roundDecimal(this.$_mx_math_getCl(object.bounds.height, measure))

        // text boxes
        const textGroup = new Paper.Group()

        const name = this.$_mx_pdf_get_area_description(object.bounds.center, area.name, 0, fontSize)
        const size = this.$_mx_pdf_get_area_description(object.bounds.center, area.size.value + ' ' + area.size.unit, 1, fontSize)

        textGroup.addChildren([name, size])
        TEXT_BOXES.push(textGroup)
        // const rect = this.$_mx_pdf_get_text_box(textGroup.bounds, new Paper.Color(63 / 255, 165 / 255, 53 / 255, 0.3))

        overviewGroup.addChildren([textGroup])

        delete area.data
      })

      const overviewSize = new Paper.Size(OVERVIEW_AREAS_SVG_WIDTH, OVERVIEW_AREAS_SVG_HEIGHT)
      const overviewFitBounds = new Paper.Rectangle(new Paper.Point(0, 0), overviewSize)

      if (this.underlayImage !== null) {
        const raster = new Paper.Raster(this.underlayImage.src)
        raster.name = 'projectUnderlay'
        raster.position = new Paper.Point(raster.bounds.width / 2, raster.bounds.height / 2)
        overviewGroup.addChild(raster)
      }

      let totalTurfPrice = 0
      areas.forEach(area => {
        if (area.breakdown.turf) {
          totalTurfPrice += area.breakdown.turf.costs.price.total.value
        }
      })

      overviewGroup.fitBounds(overviewFitBounds)
      overviewGroup.translate(new Paper.Point(-overviewGroup.bounds.x, -overviewGroup.bounds.y))

      TEXT_BOXES.forEach(trackDescription => {
        const textBox = this.$_mx_pdf_get_text_box(trackDescription.bounds.clone(), new Paper.Color(63 / 255, 165 / 255, 53 / 255, 0.3))
        textBox.insertBelow(trackDescription)
      })

      let rasterInfo = null
      if (this.underlayImage !== null) {
        const image = overviewGroup.getItem({ name: 'projectUnderlay' })
        rasterInfo = {
          scaling: { x: image.scaling.x, y: image.scaling.y },
          position: {
            x: image.bounds.x,
            y: image.bounds.y
          },
          w: image.width,
          h: image.height
        }
      }

      // const raster = overviewGroup.getItem({ class: 'Raster', name: 'projectUnderlay' })
      // overviewGroup.removeChildren(raster.index)

      return {
        areas: areas,
        overview: {
          svg: overviewGroup.exportSVG({ asString: true }),
          viewbox: `0 0 ${overviewGroup.bounds.width} ${overviewGroup.bounds.height}`
        },
        raster: rasterInfo,
        totalTurfPrice: totalTurfPrice
      }
    },
    $_mx_pdf_get_area_materials_from_product (object, tracksSum, areaSize) {
      const breakdown = {}

      breakdown.turf = this.$_mx_pdf_get_materials_turf(object.product, tracksSum, areaSize)
      breakdown.infills = this.$_mx_pdf_get_materials_infills(object.product.infills, areaSize)
      breakdown.subbases = this.$_mx_pdf_get_materials_subbases(object.product.subbases, areaSize)
      breakdown.seamtape = this.$_mx_pdf_get_materials_seamtape(object.product.seamtape, object)
      if (breakdown.seamtape !== null) {
        this.totalConsumables.seamtapes.push(breakdown.seamtape)
      }
      breakdown.adhesive = this.$_mx_pdf_get_materials_adhesive(object.product.adhesive, areaSize)
      if (breakdown.adhesive !== null) {
        this.totalConsumables.adhesives.push(breakdown.adhesive)
      }
      breakdown.total = this.$_mx_pdf_get_area_total_costs(breakdown, areaSize)

      return breakdown
    },
    $_mx_pdf_get_area_total_costs (breakdown, areaSize) {
      const sumBy = (o) => {
        if (o !== null) {
          return Number(o.costs.value)
        }
        return 0
      }
      const turfCost = Number(breakdown.turf.costs.price.total.value)
      const infillsCost = (breakdown.infills !== null) ? _.sumBy(breakdown.infills, sumBy) : 0
      const othersCost = (breakdown.subbases !== null) ? _.sumBy(breakdown.subbases.others, sumBy) : 0
      const padsCost = (breakdown.subbases !== null) ? _.sumBy(breakdown.subbases.pads, sumBy) : 0
      const pedestalsCost = (breakdown.subbases !== null) ? _.sumBy(breakdown.subbases.pedestals, sumBy) : 0
      const seamtapeCost = (breakdown.seamtape !== null) ? Number(breakdown.seamtape.costs.value) : 0
      const adhesiveCost = (breakdown.adhesive !== null) ? Number(breakdown.adhesive.costs.value) : 0

      const total = turfCost + infillsCost + othersCost + padsCost + pedestalsCost + seamtapeCost + adhesiveCost
      const each = this.mx_math_roundDecimal(total / areaSize.value)

      this.totalPrices.turf.value += turfCost
      this.totalPrices.consumables.value += (infillsCost + othersCost + padsCost + pedestalsCost + seamtapeCost + adhesiveCost)
      this.totalPrices.project.value += turfCost + infillsCost + othersCost + padsCost + pedestalsCost + seamtapeCost + adhesiveCost
      return {
        each: {
          formatted: this.$world.currencyConvert(each, true),
          value: each
        },
        price: {
          formatted: this.$world.currencyConvert(total, true),
          value: total
        }
      }
    },
    $_mx_pdf_get_materials_adhesive (adhesive, areaSize) {
      if (adhesive !== null) {
        const oAdhesive = this.getAdhesiveById(adhesive.id)

        return {
          name: this.mx_product_in_language(oAdhesive.original, ['name']),
          pim_id: oAdhesive.original.pim_id,
          coverage: oAdhesive.overlay.adhesive_coverage,
          costs: this.mx_costs_adhesive(adhesive, areaSize.value)
        }
      }

      return null
    },
    $_mx_pdf_get_materials_seamtape (seamtape, object) {
      if (seamtape !== null) {
        const tape = this.getSeamtapeById(seamtape.id)
        const costs = this.mx_costs_seamtape(seamtape, object.obj)
        return {
          name: this.mx_product_in_language(tape.original, ['name']),
          pim_id: tape.original.pim_id,
          length: {
            required: costs.length,
            roll: tape.overlay.seamtape_length
          },
          costs: costs
        }
      }

      return null
    },
    $_mx_pdf_get_materials_subbases (subbases, areaSize) {
      if (subbases.length > 0) {
        const data = {
          others: [],
          pads: [],
          pedestals: []
        }

        subbases.forEach(subbase => {
          const originalSubbase = this.getSubbaseByIdType({ type: subbase.type, id: subbase.id })

          const baseData = {
            name: this.mx_product_in_language(originalSubbase.original, ['name']),
            description: this.mx_product_in_language(originalSubbase.original, ['description']),
            pim_id: originalSubbase.original.pim_id,
            costs: null
          }

          switch (subbase.type) {
            case productTypes.PRODUCT_OTHER_SUBBASE_TYPE:
              baseData.costs = this.mx_costs_other(subbase, areaSize.value)
              this.totalConsumables.subbases.others.push(baseData)
              data.others.push(baseData)
              break
            case productTypes.PRODUCT_PAD_SUBBASE_TYPE:
              baseData.thickness = `${originalSubbase.overlay.pad_thickness} ${this.$settings.subbaseUnits.padThickness}`
              baseData.width = `${originalSubbase.overlay.pad_width} ${this.$settings.subbaseUnits.padWidth}`
              baseData.length = `${originalSubbase.overlay.pad_length} ${this.$settings.subbaseUnits.padLength}`
              baseData.costs = this.mx_costs_pad(subbase)
              this.totalConsumables.subbases.pads.push(baseData)
              data.pads.push(baseData)
              break
            case productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE:
              baseData.thickness = `${originalSubbase.overlay.pedestal_thickness} ${this.$settings.subbaseUnits.pedestalThickness}`
              baseData.width = `${originalSubbase.overlay.pedestal_width} ${this.$settings.subbaseUnits.pedestalWidth}`
              baseData.length = `${originalSubbase.overlay.pedestal_length} ${this.$settings.subbaseUnits.pedestalLength}`
              baseData.costs = this.mx_costs_pedestal(subbase)
              this.totalConsumables.subbases.pedestals.push(baseData)
              data.pedestals.push(baseData)
              break
          }
        })

        return data
      }

      return null
    },
    /**
         * @param infills
         * @param areaSize
         * @returns {null|[]}
         */
    $_mx_pdf_get_materials_infills (infills, areaSize) {
      if (infills.length > 0) {
        const data = []
        infills.forEach(infill => {
          const originalInfill = this.getInfillById(infill.id)
          const consumable = {
            name: this.mx_product_in_language(originalInfill.original, ['name']),
            pim_id: originalInfill.original.pim_id,
            description: this.mx_product_in_language(originalInfill.original, ['description']),
            costs: this.mx_costs_infill(infill, areaSize.value)
          }
          data.push(consumable)
          this.totalConsumables.infills.push(consumable)
        })

        return data
      }

      return null
    },
    /**
         * @param {} product project object of turf
         * @param {} tracksSum
         * @param {} areaSize
         *
         * @returns {{tuftMachineGauge, color, thumb: (*|null), name: *, maxWidthLength: string, category: *, finishedPileHeight}}
         */
    $_mx_pdf_get_materials_turf (product, tracksSum, areaSize) {
      const turf = this.getProductById(product.id)
      const turfInformation = turf.original.details

      const turfColor = (Object.prototype.hasOwnProperty.call(turfInformation, 'grass_zone')) ? (turfInformation.grass_zone) ? turfInformation.grass_zone.yarn_primary_colors : '' : ''
      const turfFinishedPileHeight = (Object.prototype.hasOwnProperty.call(turfInformation, 'finished_pile_height')) ? (turfInformation.finished_pile_height) ? turfInformation.finished_pile_height : '' : ''
      const tuftMachineGauge = (Object.prototype.hasOwnProperty.call(turfInformation, 'tuft_machine_gauge')) ? (turfInformation.tuft_machine_gauge) ? turfInformation.tuft_machine_gauge : '' : ''

      const data = {
        name: this.mx_product_in_language(turf.original, ['name']),
        pim_id: turf.original.pim_id,
        category: turf.original.category,
        thumb: (turf.original.assets.image !== null) ? turf.original.assets.image.conversions.thumb : null,
        color: { property_title: 'Grass Zone Color', property_text: turfColor },
        finishedPileHeight: { property_title: 'Finished Pile Height', property_text: turfFinishedPileHeight },
        tuftMachineGauge: { property_title: 'Tuft Machine Gauge', property_text: tuftMachineGauge },
        maxWidthLength: `${turf.original.roll_width} ${this.$settings.turfUnits.length} x ${turf.original.roll_length} ${this.$settings.turfUnits.length}`,
        units: {
          default: this.$settings.lengthUnit,
          square: this.$settings.areaUnit
        },
        costs: {
          totalTracksArea: this.$units.convert(tracksSum.area.value),
          totalArea: this.$units.convert(areaSize.value),
          waste: this.$units.convert(Math.abs(Number(tracksSum.area.value) - Number(areaSize.value))),
          width: this.$units.convert(turf.overlay.roll_width),
          linear: this.$units.convert(tracksSum.totalTracksOversize.length),
          price: this.mx_costs_turf(product)
        }
      }

      const squarePrice = this.mx_math_roundDecimal(data.costs.price.total.value / tracksSum.area.value)

      data.costs.squarePrice = {
        value: this.$units.convert(squarePrice),
        formatted: this.$world.currencyConvert(squarePrice, true)
      }

      return data
    },
    /**
         * Create svg for each area
         * Returns complete area information (svg, dataTable)
         *
         * @param area
         * @param measure
         * @param language
         * @returns {{svg: SVGElement | string, dataTable: *[]}}
         */
    $_mx_pdf_create_project_area_svg (area, measure, language) {
      const areaSize = new Paper.Size(SINGLE_AREA_SVG_WIDTH, SINGLE_AREA_SVG_HEIGHT)
      const areaFitBounds = new Paper.Rectangle(new Paper.Point(0, 0), areaSize)

      const areaGroup = new Paper.Group()

      // area object
      const object = this.$_mx_pdf_get_area_as_paper_object(area.data.obj)

      const fontSize = (object.bounds.height / MAX_VIEW_HEIGHT) * FONT_SIZE

      areaGroup.addChild(object)
      areaGroup.addChild(this.$_mx_pdf_get_area_units(area.data.id, area.data.points, area.data.childrenPoints, area.data.objType, measure, fontSize))

      const tracksResult = this.$_mx_pdf_get_area_tracks(area.name.replace(/^\D+/g, ''), area.data.tracks, measure, language, fontSize)
      areaGroup.addChild(tracksResult.tracks)

      if (area.data.childrenPaths.length > 0) {
        areaGroup.addChild(this.$_mx_pdf_create_child_area_text(area.data.childrenPaths, measure, fontSize))
      }

      areaGroup.addChild(this.$_mx_pdf_create_los_sign(areaGroup.bounds, area.data.viewAngle))

      areaGroup.fitBounds(areaFitBounds)
      areaGroup.translate(new Paper.Point(-areaGroup.bounds.x, -areaGroup.bounds.y))

      TEXT_BOXES.forEach(trackDescription => {
        const textBox = this.$_mx_pdf_get_text_box(trackDescription.bounds.clone(), new Paper.Color(63 / 255, 165 / 255, 53 / 255, 0.3))
        textBox.insertBelow(trackDescription)
      })

      return {
        svg: areaGroup.exportSVG({ asString: true }),
        viewbox: `0 0 ${areaGroup.bounds.width} ${areaGroup.bounds.height}`,
        dataTable: tracksResult.dataTable,
        sum: tracksResult.sum
      }
    },
    /**
         *
         * @param {paper.Rectangle} bounds
         * @param {Number} angle
         * @returns {paper.Group}
         */
    $_mx_pdf_create_los_sign (bounds, angle) {
      const y = bounds.center.y - (bounds.height / 2)

      const from = new Paper.Point(bounds.center.x, y)
      const line1 = new Paper.Path.Line(from, new Paper.Point(from.x - 5, from.y - 7))
      const line2 = new Paper.Path.Line(from, new Paper.Point(from.x + 5, from.y - 7))

      const arrowGroup = new Paper.Group()
      arrowGroup.addChildren([line1, line2])
      arrowGroup.strokeColor = 'black'
      arrowGroup.strokeCap = 'round'
      arrowGroup.rotate(angle, bounds.center)

      arrowGroup.scale((bounds.height / MAX_VIEW_HEIGHT))

      return arrowGroup
    },
    /**
         * Get children area size
         *
         * @param children
         * @param measure
         * @param fontSize
         * @returns {paper.Group}
         */
    $_mx_pdf_create_child_area_text (children, measure, fontSize) {
      const childrenAreaGroup = new Paper.Group()

      children.forEach(path => {
        const cPath = new Paper.Path(path)
        let polyPoints = Vue.svg.PathToPoly(path)

        polyPoints = polyPoints.map(item => {
          return { x: item[0], y: item[1] }
        })

        const area = Math.pow((Math.sqrt(MixinMath.methods.mx_math_calculateArea(polyPoints)) /
                    MixinMath.methods.mx_math_replaceDecimalPointWithDot(measure.fromPixels)) *
                    MixinMath.methods.mx_math_replaceDecimalPointWithDot(measure.toUnit), 2).toFixed(2)

        const areaText = new Paper.PointText(cPath.bounds.center)
        areaText.fillColor = 'black'
        areaText.content = `${area} ${this.$settings.areaUnit}`
        areaText.fontSize = fontSize
        areaText.translate(new Paper.Point(-areaText.bounds.width / 2, -areaText.bounds.height / 2))

        childrenAreaGroup.addChild(areaText)
      })

      return childrenAreaGroup
    },
    /**
         *
         * @param area
         * @returns {[paper.CompoundPath, paper.Shape.Rectangle, paper.Group]}
         * @deprecated
         */
    $_mx_pdf_create_project_overview_svg (area) {
      // area object
      const object = this.$_mx_pdf_get_area_as_paper_object(area.data.svgPath)

      // text boxes
      const textGroup = new Paper.Group()

      const name = this.$_mx_pdf_get_area_description(object.bounds.center, area.name)
      const size = this.$_mx_pdf_get_area_description(object.bounds.center, area.size.value + ' ' + area.size.unit, 1)

      textGroup.addChildren([name, size])

      const rect = this.$_mx_pdf_get_text_box(textGroup.bounds, new Paper.Color(63 / 255, 165 / 255, 53 / 255, 0.3))

      return [object, rect, textGroup]
    },
    /**
         * Returns area as paper object
         *
         * @param path
         * @returns {paper.CompoundPath}
         */
    $_mx_pdf_get_area_as_paper_object (object) {
      let paper_object = null

      switch (object.type) {
        case config.objects.types.PATH:
          paper_object = new Paper.CompoundPath(object.d())
          break
        case config.objects.types.CIRCLE: {
          const props = object.getCircleProperties()
          paper_object = new Paper.CompoundPath(new Paper.Path.Circle({
            center: [props.middle.x, props.middle.y],
            radius: props.radius
          }))
        }
      }

      paper_object.strokeColor = new Paper.Color(153 / 255, 153 / 255, 153 / 255, 1)
      paper_object.fillColor = new Paper.Color(AREA_PATH_COLOR_R / 255, AREA_PATH_COLOR_G / 255, AREA_PATH_COLOR_B / 255)
      paper_object.fillColor.alpha = AREA_PATH_ALPHA
      paper_object.strokeWidth = 1

      return paper_object
    },
    /**
         *
         * @param {String} id
         * @param {Point[]} points
         * @param {[]} childrenPoints
         * @param {String} objectType
         * @param {{}} measure
         * @param fontSize
         *
         * @returns {paper.Group}
         */
    $_mx_pdf_get_area_units (id, points, childrenPoints, objectType, measure, fontSize) {
      const unitsGroup = new Paper.Group()

      if (objectType !== config.objects.types.CIRCLE) {
        unitsGroup.addChildren(this.$_mx_pdf_get_edges_length(id, measure, new Paper.Color(69 / 255, 150 / 255, 210 / 255, 1), fontSize))
        unitsGroup.addChildren(this.$_mx_pdf_get_edges_angles(points, measure, 'black', fontSize))

        if (childrenPoints.length > 0) {
          childrenPoints.forEach(cPoints => {
            unitsGroup.addChildren(this.$_mx_pdf_get_edges_angles(cPoints, measure, 'blue', fontSize))
          })
        }
      }

      if (objectType === config.objects.types.CIRCLE) {
        // unitsGroup.addChildren(this.$_mx_pdf_get_edge_diameter(points, measure, fontSize))
      }

      return unitsGroup
    },
    /**
         * Get angle text for each edge in area
         *
         * @param {[]} points
         * @param {{}} measure
         * @param {String|paper.Color} textColor
         * @param {Number} fontSize
         *
         * @returns {paper.PointText[]}
         */
    $_mx_pdf_get_edges_angles (points, measure, textColor = 'black', fontSize = 16) {
      const fPoints = points.filter(item => item.type !== 'M' && item.type !== 'Z')
      let angles = this.mx_math_calculateAngles(fPoints, this.mx_math_isClockwise(fPoints))

      angles = angles.map((angle) => {
        const angleText = new Paper.PointText({ x: angle.x, y: angle.y })
        angleText.name = 'unitAngle'
        angleText.fillColor = textColor
        angleText.fontSize = fontSize
        angleText.content = `${this.mx_math_roundDecimal(angle.a, 0)}°`

        return angleText
      })

      return angles
    },
    /**
         * Get diameter text for areas from type circle
         *
         * @param {[]} points
         * @param {{}} measure
         * @param {String|paper.Color} textColor
         * @param {Number} fontSize
         *
         * @returns {paper.PointText[]}
         */
    $_mx_pdf_get_edge_diameter (points, measure, textColor = 'black', fontSize = 16) {
      const fPoints = points.filter(item => item.type !== 'M' && item.type !== 'Z')
      let lengths = this.mx_dom_getLinesFromObject(fPoints)

      lengths = lengths.map((length) => {
        const diameterText = new Paper.PointText({ x: fPoints[0].x, y: fPoints[0].y })
        diameterText.fillColor = textColor
        diameterText.fontSize = fontSize
        diameterText.content = `ø ${Vue.units.convert(length.cl * 2)} ${this.$settings.lengthUnit}`

        return diameterText
      })

      return lengths
    },
    /**
         * Get length text for each edge in area
         *
         * @param {String} id
         * @param {{}} measure
         * @param {String|paper.Color} textColor
         * @param {Number} fontSize
         *
         * @returns {paper.PointText[]}
         */
    $_mx_pdf_get_edges_length (id, measure, textColor = 'black', fontSize = 16) {
      const lengths = this.mx_math_calculateLengthsFromDomLines(this.mx_dom_getLinesFromObject(id), measure)

      const lengthsText = lengths.map(length => {
        const lengthText = new Paper.PointText({
          x: length.center.x,
          y: length.center.y
        })

        lengthText.name = 'unitLength'
        lengthText.fillColor = textColor
        lengthText.fontSize = fontSize
        lengthText.content = `${Vue.units.convert(length.cl)} ${this.$settings.lengthUnit}`

        return lengthText
      })

      return lengthsText
    },
    /**
         * Returns tracks and data table each track
         *
         * @param areaNumber
         * @param tracks
         * @param measure
         * @param language
         * @param fontSize
         * @returns {{dataTable: [], tracks: paper.Group}}
         */
    $_mx_pdf_get_area_tracks (areaNumber, tracks, measure, language, fontSize, isA1 = false) {
      const tracksDataTable = []
      const tracksGroup = new Paper.Group()

      // sum of dimensions, oversize, total dimension and area
      const sum = {
        tracks: {
          width: 0,
          length: 0,
          unit: ''
        },
        oversize: {
          value: 0,
          unit: ''
        },
        totalTracksOversize: {
          width: 0,
          length: 0,
          unit: ''
        },
        area: {
          value: 0,
          unit: ''
        }
      }

      tracks.forEach(track => {
        const fPoints = track.points.filter(p => p.type !== 'Z')
        const tPath = new Paper.Path()

        fPoints.forEach(p => {
          tPath.add(new Paper.Point(p.x, p.y))
        })

        tPath.closed = true
        tPath.strokeColor = new Paper.Color(63 / 255, 165 / 255, 53 / 255, 1)

        const trackDimensions = this.$_mx_pdf_get_track_dimensions(areaNumber.replace(language.trackNamePrefix, ''), tPath.pathData, track, measure, language.trackNamePrefix)
        const trackName = this.$_mx_pdf_get_area_description(tPath.bounds.center, `${trackDimensions.name}`, 0, fontSize)

        const doRound = (this.$settings.lengthUnit === 'm')
        const dimension = Number(trackDimensions.length.value) + Number(trackDimensions.oversize.value)

        const trackDimension = this.$_mx_pdf_get_area_description(tPath.bounds.center, `${trackDimensions.width.value} x ${(doRound) ? dimension.toFixed(2) : dimension} ${this.$settings.lengthUnit}`, 1, fontSize)

        const trackDescription = new Paper.Group()
        if (isA1) {
          const content = {
            isWaste: trackDimensions.waste.used,
            isSource: trackDimensions.waste.source !== null,
            contentWaste: trackDimensions.waste.data,
            contentSource: trackDimensions.waste.source
          }
          const wasteInformation = this.$_mx_pdf_get_area_description_waste(tPath.bounds.center, content, fontSize)

          if (wasteInformation && wasteInformation.length > 0) {
            trackDescription.addChildren(wasteInformation)
          }
        }

        trackDescription.addChildren([trackName, trackDimension])
        TEXT_BOXES.push(trackDescription)

        tracksDataTable.push(trackDimensions)
        tracksGroup.addChildren([tPath, trackDescription])

        // sum
        sum.tracks.width = trackDimensions.width.value
        sum.tracks.length += Number(trackDimensions.length.value)
        sum.tracks.unit = trackDimensions.width.unit
        sum.oversize.value += Number(trackDimensions.oversize.value)
        sum.oversize.unit = trackDimensions.oversize.unit
        sum.totalTracksOversize.width = trackDimensions.width.value
        if (!trackDimensions.waste.used) {
          sum.totalTracksOversize.length += Number(trackDimensions.length.value) + Number(trackDimensions.oversize.value)
        }
        sum.totalTracksOversize.unit = trackDimensions.width.unit
        sum.area.value += Number(trackDimensions.area.value)
        sum.area.unit = trackDimensions.area.unit
      })

      return {
        dataTable: tracksDataTable,
        tracks: tracksGroup,
        sum: sum
      }
    },
    /**
         * Returns dimensions of given track
         *
         * @param {String|Number} areaKey
         * @param {String} path
         * @param {{}} track
         * @param {{}} measure
         * @param {String} trackPrefix
         * @returns {{area: string, oversize: (number|string|{set(*): void, get(): *}), width: *, length: *, name: *, label: *}}
         */
    $_mx_pdf_get_track_dimensions (areaKey, path, track, measure, trackPrefix) {
      const areaOversize = Math.ceil(Number(track.oversize)) * Math.ceil(Number(track.width))
      const unitWidth = this.$settings.turfUnits.width
      const unitLength = this.$settings.turfUnits.length

      const doRound = (unitWidth !== 'm' && unitLength !== 'm')

      return {
        area: {
          value: (!track.usedWaste) ? ((!doRound) ? ((Number(track.width) * Number(track.length)) + areaOversize).toFixed(2) : (Math.ceil(Number(track.width)) * Math.ceil(Number(track.length))) + areaOversize) : 0,
          unit: this.$settings.areaUnit
        },
        width: {
          value: (!doRound) ? track.width : Math.ceil(track.width),
          unit: unitWidth
        },
        length: {
          value: (!doRound) ? track.length : Math.ceil(track.length),
          unit: unitLength
        },
        oversize: {
          value: (!doRound) ? track.oversize : Math.ceil(track.oversize),
          unit: unitLength
        },
        name: `${trackPrefix}${areaKey}-${track.name}`,
        waste: {
          used: track.usedWaste,
          data: (track.wasteData) ? track.wasteData.object.replace(config.objects.ID, 'A') + '-' + track.wasteData.track : null,
          source: (track.sourceTargetFor.length > 0) ? track.sourceTargetFor.map(target => {
            return target.object.replace(config.objects.ID, 'A') + '-' + target.track
          }) : null
        }
      }
    },
    /**
         * Creates a text for an area description in the center
         *
         * @param {paper.Point} center
         * @param {String} content
         * @param {Number} position
         * @param {Number} fontSize
         * @param {String|paper.Color} textColor
         *
         * @returns {paper.PointText}
         */
    $_mx_pdf_get_area_description (center, content, position = 0, fontSize = 16, textColor = 'white') {
      const text = new Paper.PointText({
        point: center,
        content: content,
        fillColor: textColor,
        fontSize: fontSize
      })

      switch (position) {
        case 0:
          text.translate(new Paper.Point(-text.bounds.width / 2, -text.bounds.height / 2))
          text.name = 'top'
          break
        case 1:
          text.translate(new Paper.Point(-text.bounds.width / 2, text.bounds.height / 2))
          text.name = 'bottom'
          break
      }

      return text
    },
    $_mx_pdf_get_area_description_waste (center, content, fontSize = 16, textColor = 'white') {
      if (content.isWaste || content.isSource) {
        const text = new Paper.PointText({
          point: center,
          content: (content.isWaste) ? this.$t('pdf.isRestOf') + ' ' + content.contentWaste : this.$t('pdf.restUsedFor'),
          fillColor: textColor,
          fontSize: fontSize
        })

        text.translate(new Paper.Point(-text.bounds.width / 2, (text.bounds.height / 2) + text.bounds.height))
        text.name = 'waste'

        if (content.isSource) {
          const texts = [text]

          content.contentSource.forEach((c, index) => {
            const sourceText = new Paper.PointText({
              point: center,
              content: c,
              fillColor: textColor,
              fontSize: fontSize
            })

            sourceText.translate(new Paper.Point(-sourceText.bounds.width / 2, (sourceText.bounds.height / 2) + (sourceText.bounds.height * (2 + index))))

            sourceText.name = 'waste' + index
            texts.push(sourceText)
          })

          return texts
        }

        return [text]
      }

      return null
    },
    /**
         * Creates a Rectangle for text background
         *
         * @param {paper.Rectangle} textBounds
         * @param {String|paper.Color} color
         * @param {Number} radius
         * @returns {paper.Path.Rectangle}
         */
    $_mx_pdf_get_text_box (textBounds, color = 'black', radius = 5) {
      const textBox = new Paper.Shape.Rectangle(
        new Paper.Point(textBounds.x - 5, textBounds.y - 5),
        new Paper.Size(textBounds.width + 10, textBounds.height + 10))

      textBox.fillColor = color
      textBox.radius = radius

      return textBox
    }
  }
}
