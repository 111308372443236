import { render, staticRenderFns } from "./OperationsFragmentIcon.vue?vue&type=template&id=470bc5ca"
import script from "./OperationsFragmentIcon.vue?vue&type=script&lang=js"
export * from "./OperationsFragmentIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports