import { render, staticRenderFns } from "./InfillsCosts.vue?vue&type=template&id=30253888"
import script from "./InfillsCosts.vue?vue&type=script&lang=js"
export * from "./InfillsCosts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports